.map-container {
  height: calc(100vh - 10.5rem);
  margin-bottom: 5em; 
  position: relative;
  transition: all 0.3s;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .map-container {
    height: calc(100vh - 20rem);
  }
}