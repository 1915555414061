.footer {
  font-family: "Alegreya Sans", Arial, sans-serif;
  font-weight: 400;
  border-top: 1px solid #eaeaea;
  padding: 10px 50px;
  text-align: center;
}

.credit-link {
  color: #2295a2;
  margin: 0 10px;
}

.footer-row {
  margin: auto;
  width: 70%;
}
