.form {
  margin-top: 2rem;
}

.form__desc {
  color: #124e54;
  font-size: 1rem;
}

.form label {
  color: #124e54;
}

.form__error-message {
  color: red;
}

.directory-form {
  transition: all 0.3s;
}

.fieldError {
  font-size: 1rem;
}

/* Styles copied from Bootstrap */
.form input,
.form select,
.form textarea {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #124e54;
  background-color: #f6fbfb;
  background-clip: padding-box;
  border: 1px solid #c7e4e7;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form textarea {
  height: 6rem;
}

.form input:focus,
.form select:focus,
.form textarea:focus {
  border-color: #2295a2;
  box-shadow: none;
  outline: 0;
}

.form input::placeholder,
.form select::placeholder,
.form textarea::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form input:focus::placeholder,
.form select:focus::placeholder,
.form textarea:focus::placeholder {
  color: #495057;
}

.required:after {
  color: red;
  content: " *";
}

.form input.new-data,
.form select.new-data, 
.form textarea.new-data {
  border-color: red;
}

.old-data {
  color: red;
  font-style: italic;
  word-break: break-word;
}

.old-data::before {
  content: "Previously:";
  font-style: normal;
  margin-right: .4rem;
}