.navbar-light {
  font-family: "Alegreya Sans", Arial, sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #eaeaea;
}

.fixed-bottom,
.fixed-top {
  position: initial;
  right: 0;
  left: 0;
  z-index: 1030;
}

.hamburgerDropDown {
  background-color: #124e54;
  border: 1px solid bisque;
  position: absolute;
  top: 50px;
  right: -1rem;
  z-index: 1000;
}

ul.hamburgerDropDown {
  padding: 10px;
}

.navbar div {
  display: inline-block;
}

.nav-link {
  color: #2295a2;
  padding: 0;
}

.navbar ul {
  justify-content: space-between;
}

.navbar img {
  max-width: 100%;
  height: auto;
}
