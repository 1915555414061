.results-container {
  display: flex;
  flex-flow: wrap;
}
.result-items {
  position: relative;
  padding: 5px;
  border: 1px solid #898989;
  margin: 16px;
  text-align: left;
  width: 100%;
  /* min-width: 200px; */
  text-decoration: none;
}

.loading {
  text-align: center;
  padding-top: 10px;
}
