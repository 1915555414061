.App {
  width: 75vw;
  max-width: 1140px;
  margin: auto;
  height: inherit;
}

.buttonStyle {
  margin: "10px 10px 10px 10px";
  color: #f6fbfb;
  background-color: #2295a2;
  border-color: #2295a2;
}

.btn-group-toggle > .buttonStyle {
  color: #f6fbfb;
}

.buttonStyle:hover, .buttonStyle:focus, 
.btn-toggle:not(.active):hover, .btn-toggle:not(:disabled):not(.disabled).active  {
  background-color: #124e54;
  border-color: #7ABFC7;
}

.btn-secondary, 
.btn-toggle:not(.active) {
  background-color: #2295a2;
  border-color: #2295a2;
}

.formInputStyle {
  color: #124e54;
}

.navbar-brand {
  min-width: 20%;
}

@media (min-width: 700px) {
  .navbar-brand {
    width: auto;
  }
}

@media only screen and (max-width: 600px) {
  .App {
    width: 100vw;
  }
}


.modal-90w {
  width: 90%;
  max-width: 90%;
}